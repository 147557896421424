/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import {
  mergeCraftPreview,
  resolveCB,
  resolveTeaching,
  resolveMeta,
} from "@dataResolvers";
import { Teaching as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query($slug: [String]) {
    craft {
      entries(section: "teaching") {
        ... on Craft_teaching_teaching_Entry {
          type: typeHandle
          url
          slug
          title
          teachingExcerpt
          mediaUrl
          mediaEmbed
          postDate
          showDate
          teachingType
          teachingImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entry(section: "teaching", slug: $slug) {
        ... on Craft_teaching_teaching_Entry {
          # meta
          url
          slug
          title
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
          metaTitle
          uid
          type: typeHandle
          url
          slug
          title
          teachingExcerpt
          mediaUrl
          mediaEmbed
          postDate
          showDate
          teachingType
          teachingImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # contentBuilder
          contentBuilder {
            ... on Craft_contentBuilder_heading_BlockType {
              enabled
              id
              typeHandle
              heading
            }
            ... on Craft_contentBuilder_subheading_BlockType {
              enabled
              id
              typeHandle
              subheading
              enabled
            }
            ... on Craft_contentBuilder_leadText_BlockType {
              enabled
              id
              typeHandle
              leadText
            }
            ... on Craft_contentBuilder_copy_BlockType {
              enabled
              id
              typeHandle
              copy
              indent
            }
            ... on Craft_contentBuilder_list_BlockType {
              enabled
              id
              typeHandle
              listItem
              enabled
              restartCount
            }
            ... on Craft_contentBuilder_image_BlockType {
              enabled
              id
              typeHandle
              enabled
              indent
              images {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
            ... on Craft_contentBuilder_video_BlockType {
              enabled
              id
              typeHandle
              indent
              videoUrl
              caption
            }
            ... on Craft_contentBuilder_link_BlockType {
              enabled
              id
              typeHandle
              linkLink {
                text
                url
              }
              indent
              removeTopMargin
            }
            ... on Craft_contentBuilder_button_BlockType {
              enabled
              id
              typeHandle
              linkLink {
                text
                url
              }
              indent
              removeTopMargin
            }
            ... on Craft_contentBuilder_blockQuote_BlockType {
              enabled
              id
              typeHandle
              quote
              citation
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `
query($slug: [String]) {
  craft {
    entries(section: "teaching") {
      ... on Craft_teaching_teaching_Entry {
        type: typeHandle
        url
        slug
        title
        teachingExcerpt
        mediaUrl
        mediaEmbed
        postDate
showDate
        teachingType
        teachingImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
      }
    }
    entry(section: "teaching", slug: $slug) {
      ... on Craft_teaching_teaching_Entry {
        uid
        type: typeHandle
        url
        slug
        title
        teachingExcerpt
        mediaUrl
        mediaEmbed
        postDate
showDate
        teachingType
        teachingImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # contentBuilder
        contentBuilder {
          ... on Craft_contentBuilder_heading_BlockType {
            enabled
            id
            typeHandle
            heading
          }
          ... on Craft_contentBuilder_subheading_BlockType {
            enabled
            id
            typeHandle
            subheading
            enabled
          }
          ... on Craft_contentBuilder_leadText_BlockType {
            enabled
            id
            typeHandle
            leadText
          }
          ... on Craft_contentBuilder_copy_BlockType {
            enabled
            id
            typeHandle
            copy
            indent
          }
          ... on Craft_contentBuilder_list_BlockType {
            enabled
            id
            typeHandle
            listItem
            enabled
            restartCount
          }
          ... on Craft_contentBuilder_image_BlockType {
            enabled
            id
            typeHandle
            enabled
            indent
            images {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_contentBuilder_video_BlockType {
            enabled
            id
            typeHandle
            indent
            videoUrl
            caption
          }
          ... on Craft_contentBuilder_link_BlockType {
            enabled
            id
            typeHandle
            linkLink {
              text
              url
            }
            indent
            removeTopMargin
          }
          ... on Craft_contentBuilder_button_BlockType {
            enabled
            id
            typeHandle
            linkLink {
              text
              url
            }
            indent
            removeTopMargin
          }
          ... on Craft_contentBuilder_blockQuote_BlockType {
            enabled
            id
            typeHandle
            quote
            citation
          }
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    // meta
    title,
    metaTitle,
    metaImage,
    metaDescription,
    contentBuilder,
    slug,
    teachingType,
    url,
    mediaUrl,
    mediaEmbed,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    mediaUrl,
    mediaEmbed,
    // TODO: Clean this up, it's a bit hacky
    post: [resolveTeaching(craft.entry)].map(t => {
      return {
        label: "Featured Event",
        title: t.title,
        description: t.excerpt,
        type: {
          text: t.type,
          icon: t.type,
        },
        tag: { text: t.type, slug: t.type },
        time: t.time,
        mediaUrl: t.mediaUrl,
        mediaEmbed: t.mediaEmbed,
        image: t.image,
        link: { url: t.url, text: t.label },
      };
    })[0],
    related: {
      ...craft.entries
        .filter(e => e.slug !== slug && e.teachingType === teachingType)
        .map(e => resolveTeaching(e))
        .map(t => {
          return {
            name: t.title,
            description: t.excerpt,
            type: {
              text: t.type,
              icon: t.type,
            },
            time: t.time,
            mediaUrl: t.mediaUrl,
            mediaEmbed: t.mediaEmbed,
            image: t.image,
            button: { url: t.url, text: t.label },
          };
        })[0],
      label: "Related Teaching",
    },
    contentBuilder: resolveCB(contentBuilder),
    url,
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
